import React from 'react';

const YouTubeEmbed = ({ videoId }) => {
    return (
        <div className="w-full h-full">
          <div className="flex justify-center w-full h-full ">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className='w-3/5 h-3/5'
            />
          </div>
        </div>
      );
};

export default YouTubeEmbed;