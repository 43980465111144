import React from 'react';

const Spinner = () => {
    return (
        <div className="absolute top-0 left-0">
            <div className="flex justify-center items-center h-screen w-screen bg-white opacity-50">
                <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 border-solid rounded-full animate-spin"></div>
            </div>
        </div>
    );
};

export default Spinner;