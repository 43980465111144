import React, { useContext, useEffect, useState } from 'react';

import UserVendorsBills from './userVendorsBills';
import Vendorbillings from './vendorBillings';

const Billing = ({ loggedUser }) => {
    
    const [vendorID, setVendorID] = useState(null);

    useEffect(()=>{
        
        if (loggedUser){
            setVendorID(loggedUser.vendorID);

        }
    },[loggedUser])

    const onVendorSelected = (item) => {
        //console.log("Vendor Selected", item);

        setVendorID(item.vendorID);

    }

    return (
        <div>
            <div className='text-2xl ml-2  font-raleway'>
                Mensalidades
            </div>
            <div className='text-xs ml-2  font-raleway'>
                Gerencie e pague aqui as mensalidades do AiGarçom, conforme seu uso.
            </div>
            <div className='flex flex-row w-full'>
                <div className='ml-5 flex-shrink-0 w-80'>
                    {loggedUser &&
                        <UserVendorsBills userID={loggedUser.userID} selectedVendorID={vendorID} onVendorSelected={onVendorSelected}/>
                    }
                </div>
                <div className='ml-5'>
                    {loggedUser && vendorID && 
                        <Vendorbillings vendorID={vendorID} 
                        userToken={loggedUser.userToken}  />
                    }
                </div>

            </div>
        </div>
    )
};

export default Billing;