import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moreDetailsImg from '../images/moredetails.png'
// import { IoReorderThreeOutline, IoClose } from 'react-icons/io5';

function MoreDetails() {

    return (
        <div className="flex flex-col md:flex-row">
            <div className="flex-1 p-4">
                <p className="px-6 text-2xl md:text-4xl font-raleway">
                    Simplifique o atendimento no seu bar ou restaurante em um Único App
                </p>
                <div className="pt-2">
                    <p className="px-6 text-base md:text-base font-raleway">
                        O AiGarçom é a solução completa para bares e restaurantes que
                        querem simplificar seu dia a dia e revolucionar a experiência dos clientes.
                        Com o nosso app você pode agilizar e organizar todo o processo de
                        atendimento.
                    </p>
                    <p className="px-6 text-base md:text-base font-raleway">
                        Aumente sua produtividade e melhore a experiência dos seus
                        clientes com o <span className='font-bold'>AiGarçom</span>.
                    </p>
                    <p className="px-6 pt-3 text-lg md:text-2xl font-bold font-raleway">
                        Por que Escolher o AiGarçom?
                    </p>

                    <div className="px-6 pt-2">
                        <div className="text-base font-bold font-raleway">
                            Pedidos Simples e Rápidos
                        </div>
                        <div className="text-sm font-raleway">
                            Atendentes e clientes podem realizar pedidos diretamente pelo app.
                            Os pedidos vão direto para as áreas de preparação (cozinha, bar, churrasqueira),
                            reduzindo erros e tempo de espera e são exibidos instantaneamente
                            em telas ou impressoras.
                        </div>
                        <div className="text-base pt-2 font-bold font-raleway">
                            Acompanhamento de Pedidos em Tempo Real
                        </div>
                        <div className="text-sm font-raleway">
                            Tanto os atendentes quanto os clientes podem acompanhar
                            o status dos pedidos em tempo real. Quando o pedido estiver pronto,
                            uma notificação é enviada, agilizando o atendimento.
                        </div>
                        <div className="text-base pt-2 font-bold font-raleway">
                            Fechamento de Conta Simplificado
                        </div>
                        <div className="text-sm font-raleway">
                            Basta selecionar as comandas, escolher a forma de pagamento,
                            e a conta é impressa para conferência.
                            O cliente ainda tem a opção de pagar via PIX diretamente pelo aplicativo,
                            proporcionando economia, conveniência e segurança.
                        </div>
                        <div className="text-base pt-2 font-bold font-raleway">
                            Controle Financeiro
                        </div>
                        <div className="text-sm font-raleway">
                            Faça o fechamento de caixa, visualize o resumo de movimento
                            e tenha o controle total do seu estabelecimento de qualquer lugar.
                            O AiGarçom é totalmente online!                        </div>
                        <div className="text-base pt-2 font-bold font-raleway">
                            Aplicativo de Autoatendimento
                        </div>
                        <div className="text-sm font-raleway">
                            O cliente pode chamar o garçom ou fazer pedidos diretamente pelo smartphone,
                            otimizando a produtividade dos atendentes e elevando a experiência do cliente a outro nível.
                        </div>
                        <div className="text-base pt-2 font-bold font-raleway">
                            Funcionalidades Que Facilitam o Seu Dia a Dia
                        </div>
                        <div className="text-sm font-raleway">
                            <ul className="list-disc pl-5">
                                <li>
                                    Integração com pagamento via PIX.
                                </li>
                                <li>
                                    Manutenção de cardápio simplificada.
                                </li>
                                <li>
                                    Impressão de pedidos, fechamento de contas e conciliação de caixa.
                                </li>
                                <li>
                                    Gerenciamento totalmente online,
                                    permitindo que você administre seu estabelecimento
                                    de qualquer lugar,
                                    usando apenas seu smartphone.
                                </li>
                            </ul>
                        </div>
                        <div className="text-base pt-2 font-bold font-raleway">
                            Ideal para estabelecimentos de todos os tamanhos
                        </div>
                        <div className="text-sm font-raleway">
                            <div className="text-sm font-raleway">
                                O <span className='font-bold'>AiGarçom</span> oferece 
                                planos acessíveis com opções para estabelecimentos com alta demanda. 
                                Escolha o plano que se encaixa no seu negócio!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1 items-center justify-center">
                <img src={moreDetailsImg} alt="MoreDetails" className="w-[45vh] md:w-[600px]" />
            </div>
        </div >

    );
}

export default MoreDetails;