
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoAddCircleOutline, IoRemoveCircleOutline, IoArrowUndoCircleOutline, IoThumbsUpOutline } from 'react-icons/io5';

import AppContext from '../services/AppContext';

import { decimalToMoney } from '../services/myFunctions';

import { calcOrderItemTotals, getUniqueOrderItems } from '../services/data';

import AppHeader from './appHeader';
import Header from './header';
import SubHeader from './subHeader';
import AddObs from './addObs';


const Cart = () => {

    const { orderToPlace } = useContext(AppContext)
    const [totalQuantity, settotalQuantity] = useState(0);
    const [totToPay, setTotToPay] = useState(0);
    const [orderItems, setOrderItems] = useState([]);
    const [downloading, setDownloading] = useState(false);

    const [obsModalVisible, setObsModalVisible] = useState(false);
    const [itemObs, setitemObs] = useState("");
    const selectedItem = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for some browsers to show the confirmation dialog
            
        };

        // Adding the event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        if (orderToPlace.current == null) {
            //garantir que seja zero caso seja null
            alert("Seu carrinho está vazio! Escolha seus items no cardápio")
            navigate("/menu");
            return;
        } else if (orderToPlace.current.totalQuantity == 0) {
            //garantir que seja zero caso seja null
            alert("Seu carrinho está vazio! Escolha seus items no cardápio")
            navigate("/menu");
            return;
        }

        getOrder();
    }, [])

    const getOrder = () => {
        //setOrder(orderToPlace.current.order);
        setOrderItems(getUniqueOrderItems(orderToPlace.current.orderItems));
        settotalQuantity(orderToPlace.current.totalQuantity);
        setTotToPay(orderToPlace.current.totToPay);
    }


    const onBackToMenu = () => {
        navigate('/menu');
    }
    const onOpenPlaceOrder = () => {

        navigate('/placeOrder');
    }

    const onMinus = (item) => {

        registerOrderItems(item, -1);
        getOrder();
    }

    const onPlus = (item) => {

        registerOrderItems(item, 1);
        getOrder();
    }

    const onDeleteItem = (item) => {

        while (true) {
            const i = orderToPlace.current.orderItems.findIndex(row => item.productID === row.productID);

            if (i == -1) {
                break;
            }
            else {
                orderToPlace.current.orderItems.splice(i, 1);
            }
        }

        calcOrderItemTotals(orderToPlace.current, item.productID)
        getOrder();

    }

    const registerOrderItems = (item, quantity = 1) => {

        const toSave = {
            "orderID": item.orderID, "productID": item.productID,
            "quantity": quantity, "price": item.price, "totPrice": item.price * quantity,
            "productDescription": item.productDescription,
            "productCode": item.productCode, "url": item.url, "itemObs": item.itemObs
        };

        // orderToPlace.current.orderItems.push(toSave);

        const i = orderToPlace.current.orderItems.findIndex(row =>
            row.productID === item.productID
        )

        if (i == -1) {
            orderToPlace.current.orderItems.push(toSave);
        }
        else {
            orderToPlace.current.orderItems[i].quantity = orderToPlace.current.orderItems[i].quantity + quantity
        }

        calcOrderItemTotals(orderToPlace.current, item.productID)

    };

    const onAddItemObs = (item) => {
        setitemObs(item.itemObs);
        selectedItem.current = item.productID;

        setObsModalVisible(true);
    }

    const closeObsModal = () => {

        const i = orderToPlace.current.orderItems.findIndex(row =>
            row.productID === selectedItem.current
        )

        if (i != -1) {
            orderToPlace.current.orderItems[i].itemObs = itemObs;
        }

        getOrder();

        setObsModalVisible(false);
    }

    const renderItem = ({ item }) => {
        return (
            <div className="bg-white shadow-md rounded-lg p-1 mb-4">
                <div className="flex justify-center">
                    <div className="flex w-16 p-1">
                        <div>
                            <img
                                src={item.url}
                                alt="Product"
                                className="rounded-full object-contain"
                            />
                        </div>
                    </div>
                    <div className="flex w-full flex justify-start">
                        <div className="text-base font-normal ml-2">
                            {item.productCode} {item.productDescription}
                        </div>
                    </div>
                    <div className="flex w-16 h-8 flex justify-end">
                        <button onClick={() => onDeleteItem(item)} className="border p-2 py-1 border-bgTitle rounded-full">
                            <p className="text-center text-base font-normal">
                                Excluir
                            </p>
                        </button>
                    </div>
                </div>

                <div className="flex mt-1">
                    <div className="flex w-20 items-center">
                        <p className="text-base font-normal">
                            {decimalToMoney(item.price)}
                        </p>
                    </div>
                    <div className="flex w-full items-center">
                        <p className="text-xl font-bold ml-2">
                            x
                        </p>

                        {item.quantity > 1 ? (
                            <button onClick={() => onMinus(item)}>
                                <IoRemoveCircleOutline className="text-black ml-2 w-10 h-10" />
                            </button>
                        ) : (
                            <div className="flex items-center">
                                <IoRemoveCircleOutline className="text-gray-200 ml-2 w-10 h-10" />
                            </div>
                        )}

                        <p className="text-2xl font-normal ml-2 text-gray-500">
                            {item.quantity}
                        </p>

                        <button onClick={() => onPlus(item)}>
                            <IoAddCircleOutline className="w-10 h-10 ml-2" />
                        </button>
                    </div>
                    <div className="flex-2 flex items-center justify-end mr-4">
                        <p className="font-bold">
                            {decimalToMoney(item.totPrice)}
                        </p>
                    </div>
                </div>

                <div className="mt-2 flex justify-start">
                    <button onClick={() => onAddItemObs(item)} className="text-blue-500 underline">
                        {item.itemObs !== "" ? item.itemObs : "Observações"}
                    </button>
                </div>
            </div>
        );
    }
    return (
        <div className="flex flex-col justify-start h-screen">

            {orderToPlace.current != null &&
                <div>
                    <AppHeader />
                    <Header title="Carrinho de compras" name={orderToPlace.current.order.vendorName} />
                </div>
            }

            <div className="flex justify-between mx-2 my-2">
                <div className="bg-white shadow-md rounded-lg p-2">
                    <p className="text-base font-normal">Qtd. Items</p>
                    <p className="text-lg font-bold text-center">{totalQuantity}</p>
                </div>

                <div className="bg-white shadow-md rounded-lg p-2">
                    <p className="text-base text-center font-normal">Total Pedido</p>
                    <p className="text-lg font-bold text-center">{decimalToMoney(totToPay)}</p>
                </div>
            </div>

            <SubHeader title="Items solicitados" />

            {downloading && (
                <div className="flex justify-center mt-5">
                    <div className="loader large"></div> {/* Replace with your actual loading component */}
                </div>
            )}

            {!downloading && (
                <div className="mt-1 mx-2 h-[420px] overflow-y-auto">
                    <ul>
                        {orderItems.map((item) => renderItem({ item }))} {/* Assuming renderItem is a function that returns JSX */}
                    </ul>
                </div>
            )}

            <div className="fixed bottom-2 w-full">
                <div className="flex justify-between mx-2">
                    <div className="border p-2 border-blue-500 rounded-lg">
                        <button onClick={onBackToMenu} className="flex items-center">
                            <IoArrowUndoCircleOutline className="w-5 h-5 text-bgButton" />
                            <p className="text-base font-bold text-bgButton ml-2">Ops! Faltou coisa</p>
                        </button>
                    </div>

                    <div className="bg-bgButton p-2 rounded-lg">
                        <button onClick={onOpenPlaceOrder} className="flex items-center">
                            <IoThumbsUpOutline className="w-5 h-5 text-white" />
                            <p className="text-base font-bold text-white ml-2">Tudo certo! Pedir</p>
                        </button>
                    </div>
                </div>
            </div>

            <AddObs
                visible={obsModalVisible}
                closeModal={closeObsModal}
                observation={itemObs}
                setObservation={setitemObs}
            />
        </div>
    );
};

export default Cart;
