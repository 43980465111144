
import React from 'react';

const SubHeader = (props) => {

    return (
        <div className="pl-2 py-1.5 flex justify-center">
          <p className="font-bold text-bgButton">
            {props.title}
          </p>
        </div>
      );
      };

export default SubHeader;
