import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const PrintTabs = () => {
    const location = useLocation();
    const { vendorName } = location.state || {}; // Acessando os parâmetros

    const [qtd, setQtd] = useState(100);

    const gridItems = Array.from({ length: qtd }, (_, i) => i + 1); // Creates an array of numbers 1 to 36

    const handlePrint = () => {
        window.print();
    };


    return (
        <div>
            <div className="flex print:hidden w-full my-5 justify-center">
                <div className="flex flex-col print:hidden w-full my-5">
                    <div className='flex flex-row w-full justify-center'>
                        <div className='flex text-2xl font-bold mr-5'>
                            Foram geradas {qtd} comandas
                        </div>
                        <button
                            onClick={() => setQtd(qtd + 100)}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Gerar + 100
                        </button>

                    </div>
                    <div className='flex flex-row w-full justify-center mt-5'>
                        <button
                            onClick={handlePrint}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Imprimir esta página
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center min-h-screen">
                <div className="w-full max-w-[210mm] bg-white">
                    <div className="grid grid-cols-4 grid-rows-8">
                        {gridItems && gridItems.map((item) => (
                            <div key={item} className='flex flex-col justify-center  
                                w-50
                                border border-sm border-gray-600
                                p-4
                                print:h-[45mm] print:w-[48mm]
                                print:break-inside-avoid'>
                                <div className='flex justify-center text-1xl text-bold font-arial'>
                                    {vendorName}
                                </div>
                                <div className='flex justify-center text-2xl text-bold font-arial'>
                                    Comanda
                                </div>
                                <div className='flex justify-center text-4xl text-bold font-arial'>
                                    {item.toString().padStart(4, '0')}
                                </div>
                                <div
                                    className="flex 
                                    items-center justify-center  
                                    print:h-[43mm] print:w-[43mm]"
                                >
                                </div>
                                <div key={item} className='flex justify-center text-xs'>Baixe o App em</div>
                                <div key={item} className='flex justify-center text-xs'>www.aigarcom.com.br</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrintTabs;
