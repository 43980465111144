import React from 'react';

const AddObs = ({ visible, closeModal, setObservation, observation }) => {

    return (
        <div className="flex flex-1 justify-center items-center">
            {visible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white shadow-md rounded-lg p-4 w-[calc(100vw-20px)] h-60">

                        <div>
                            <div className="flex flex-row">
                                <p className="text-base font-normal">Observações (Opcional)</p>
                            </div>

                            <textarea
                                className="border rounded-md p-2 mt-2 w-[calc(100vw-40px)] h-24"
                                value={observation}
                                placeholder="Sem cebola... com gelo e limão no copo ..."
                                onChange={(e) => setObservation(e.target.value)}
                            />
                        </div>

                        <div className="flex justify-center mt-5">
                            <button onClick={closeModal} className="bg-transparent border border-blue-500 p-2 rounded-lg w-24 text-center">
                                <p className="text-base font-normal text-blue-500">Fechar</p>
                            </button>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
};

export default AddObs;


