import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoPersonCircleOutline } from 'react-icons/io5';
import Billing from './billing';
import Documents from './documents';
import Downloads from './downloads';
import Reports from './reports';
import PrintStation from './printStation';
import AppContext from '../services/AppContext';

const Dashboard = () => {
  const { selViewRef } = useContext(AppContext);

  const [loggedUser, setLoggedUser] = useState(null);
  // State to manage the selected component
  const [selectedComponent, setSelectedComponent] = useState(selViewRef.current);

  const navigate = useNavigate();

  useEffect(() => {
    const lsLoggedUser = localStorage.getItem('loggedUser');

    if (lsLoggedUser) {
      setLoggedUser(JSON.parse(lsLoggedUser));
    }
    else {
      navigate('/login');
    }

  }, [])

  // Function to render the selected component in the main area
  const renderComponent = () => {

    if (selectedComponent !== "close"){
      selViewRef.current = selectedComponent;
    }

    switch (selectedComponent) {
      case 'billing':
        return <Billing loggedUser={loggedUser} />;
      // case 'download':
      //   return <Download />;
      case 'documents':
        return <Documents />;
      case 'downloads':
        return <Downloads />;
      case 'reports':
        return <Reports loggedUser={loggedUser} />;
      case 'printQRCode':
        return <PrintStation
          loggedUser={loggedUser}
          title="Selecione o estabelecimento para gerar e imprimir o QRCode para fixar nas mesas."
          pageToPrint={"printQRCode"} />;
      case 'printTabs':
        return <PrintStation
          loggedUser={loggedUser}
          title="Selecione o estabelecimento para gerar e imprimir as comandas numeradas."
          pageToPrint={"printTabs"} />;
      case 'close':
        navigate("/");

      default:
        return <Billing loggedUser={loggedUser} />;
    }
  };

  return (
    <div className="flex h-screen">
      <nav className="fixed w-full bg-bgTitle">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex flex-shrink-0 items-center">
              <img
                src="https://smartapplications.com.br/images/logoaigarcom2.png"
                alt="Logo"
                className="h-6"
              />
            </div>
            {loggedUser &&
              <div className='flex items-center'>
                <div className="hidden md:flex flex-row items-center">
                  <IoPersonCircleOutline className="h-7 w-7" />
                  <div className="flex ml-2 w-1/4 items-center">
                    {loggedUser.userEmail}
                  </div>

                </div>
                <div className="md:hidden flex items-center justify-center w-5 h-5 
                rounded-full bg-purple-700 text-white text-xs">
                  {loggedUser.userEmail?.charAt(0)}
                </div>
              </div>}


          </div>
        </div>

      </nav>


      {/* Coluna 1: Menu Vertical */}
      <div className="w-1/6 bg-gray-800 text-white p-5">
        <h2 className="mt-20 text-lg font-bold mb-5">Menu</h2>
        <ul>
          <li className="mb-4 hover:bg-gray-700 p-2 rounded"
            onClick={() => setSelectedComponent('billing')}>
            Financeiro
          </li>
          <li className="mb-4 hover:bg-gray-700 p-2 rounded"
            onClick={() => setSelectedComponent('documents')}>
            Documentação
          </li>
          <li className="mb-4 hover:bg-gray-700 p-2 rounded"
            onClick={() => setSelectedComponent('downloads')}>
            Downloads
          </li>
          <li className="mb-4 hover:bg-gray-700 p-2 rounded"
            onClick={() => setSelectedComponent('printQRCode')}>
            Gerar QRCode das mesas
          </li>
          <li className="mb-4 hover:bg-gray-700 p-2 rounded"
            onClick={() => setSelectedComponent('printTabs')}>
            Gerar Comandas
          </li>
          <li className="mb-4 hover:bg-gray-700 p-2 rounded"
            onClick={() => setSelectedComponent('reports')}>
            Relatórios</li>
          <li className="mb-4 hover:bg-gray-700 p-2 rounded"
            onClick={() => setSelectedComponent('close')}>
            Sair
          </li>
        </ul>
      </div>
      {/* Coluna 2: Dashboard */}
      <div className="w-5/6">
        <div className="mt-20">
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;