import React, {useRef, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { AppContextProvider } from './services/AppContext'
import { VendorUserRef, VendorRef, LoggedUser, TabRef } from './services/data';


import Home from './components/home';
import CreateUser from './components/createUser';
import AppHome from './components/appHome';
import StartNow from './components/startNow';
import DownloadApp from './components/downloadApp';
import Dashboard from './components/dashboard';
import Login from './components/login';
import PrintQRCode from './components/printQRCode';
import PrintTabs from './components/printTabs';
import Menu from './components/menu';
import Cart from './components/cart';
import PlaceOrder from './components/placeOrder';
import TabInfo from './components/tabInfo';
import Search from './components/search';
import CallWaiter from './components/callWaiter';

function App() {
  const newOrder = useRef(true);
  const orderToPlace = useRef(null);
  const locationRef = useRef(null);
  const loggedUser = useRef(LoggedUser)
  const [userNameHeader, setUserNameHeader] = useState("");
  const vendorRef = useRef(VendorRef);
  const tabRef = useRef(TabRef);
  const vendorUserRef = useRef(VendorUserRef);
  const selViewRef = useRef("finance");
  const qrCodeInfoRef = useRef(null);

  return (
    <AppContextProvider value={{
      loggedUser,
      orderToPlace,
      newOrder,
      locationRef,
      userNameHeader,
      setUserNameHeader,
      tabRef,
      vendorRef,
      vendorUserRef,
      selViewRef,
      qrCodeInfoRef
    }}>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/appHome" element={<AppHome />} />
          <Route path="/startNow" element={<StartNow/>} />
          <Route path="/downloadApp" element={<DownloadApp/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/printQRCode" element={<PrintQRCode/>} />
          <Route path="/printTabs" element={<PrintTabs/>} />
          <Route path="/menu" element={<Menu/>} />
          <Route path="/cart" element={<Cart/>} />
          <Route path="/placeOrder" element={<PlaceOrder/>} />
          <Route path="/tabInfo" element={<TabInfo/>} />
          <Route path="/search" element={<Search/>} />
          <Route path="/callWaiter" element={<CallWaiter/>} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
