import React from 'react';

const ChatBot = () => {

  const interceptMessage = ()=>{
    console.log("****** Button clicked *******")
  }

  //document.querySelector('#sendButton').addEventListener('click', interceptMessage);

  return (
    <div className="w-full md:w-[500px] h-full min-h-[500px] border border-xm border-gray-500">
      <iframe
        src="https://www.chatbase.co/chatbot-iframe/FrTvKIT8wUdThD2eW4xWA"
        className="w-full h-full min-h-[500px]"
        frameBorder="0"
        title="ChatBot"
      ></iframe>
    </div>
  );
};

export default ChatBot;
