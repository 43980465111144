import React, { useEffect, useState } from 'react';
import UserVendors from "./userVendors";
import Charts from "./charts";

function Reports({ loggedUser }) {

    const [vendorID, setVendorID] = useState(null);

    useEffect(() => {

        if (loggedUser) {
            setVendorID(loggedUser.vendorID);

        }
    }, [loggedUser])

    const onVendorSelected = (item) => {
        //console.log("Vendor Selected", item);

        setVendorID(item.vendorID);

    }

    return (
        <div className='ml-3'>
            <h1 className="font-bold font-base font-raleway">Relatórios Gerenciais</h1>
            <div className='flex flex-row space-x-5'>

                <div className='flex w-1/5' >

                    {loggedUser &&
                        <UserVendors
                            userID={loggedUser.userID}
                            onVendorSelected={onVendorSelected}
                            selectedVendorID={vendorID} />
                    }
                </div>
                <div className='flex w-4/5'>
                    {loggedUser && vendorID &&
                        <Charts vendorID={vendorID} userToken={loggedUser.userToken} />
                    }
                </div>
            </div>
        </div>

    );
}

export default Reports;