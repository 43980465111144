import React, { useState, useEffect, useContext, useRef, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import AppHeader from './appHeader';
import Header from './header';
import AppContext from '../services/AppContext';

import { IoAddOutline, IoSearchOutline } from 'react-icons/io5';


import { decimalToMoney } from '../services/myFunctions';
import { calcOrderItemTotals } from '../services/data';


const Search = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { params_products } = location.state || {}; // Acessando os parâmetros

  const { vendorRef, orderToPlace } = useContext(AppContext);
  const [productDescription, setProductDescription] = useState("");
  const [products, setProducts] = useState([]);

  const allProducts = useRef(params_products)
  const textInputRef = useRef(null);

  // useEffect(() => {
  //   if (textInputRef.current) {
  //     textInputRef.current.focus();
  //   }
  // }, []);



  useEffect(() => {
    if (productDescription != "") {

      //console.log(allProducts.current[0].category);

      const filteredProducts = allProducts.current.filter(item => (item.category + item.productCode + item.productDescription).toLowerCase().includes(productDescription.toLowerCase()));

      setProducts(filteredProducts);

    }
  }, [productDescription]);

  const registerOrderItems = (item, quantity = 1) => {

    const toSave = {
      "orderID": orderToPlace.current.order.orderID, "productID": item.productID,
      "quantity": quantity, "price": item.price, "totPrice": item.price * quantity,
      "productDescription": item.productDescription, "productCode": item.productCode,
      "url": item.url, "itemObs": ""
    };

    const i = orderToPlace.current.orderItems.findIndex(row =>
      row.productID === item.productID
    )

    if (i == -1) {
      orderToPlace.current.orderItems.push(toSave);
    }
    else {
      orderToPlace.current.orderItems[i].quantity = orderToPlace.current.orderItems[i].quantity + quantity
    }

    calcOrderItemTotals(orderToPlace.current, item.productID)

  };


  const addItem = (item) => {

    registerOrderItems(item);

    navigate("/menu");
  }

  const renderItem = ({ item }) => {
    return (
      <MenuItem item={item} />
    )
  }

  const MenuItem = memo(function row({ item }) {
    return (
      <div
        onClick={() => addItem(item)}
        className="flex items-center m-1 p-2 bg-white shadow-md rounded-lg"
      >
        <img
          src={item.url}
          alt={item.productDescription}
          className="w-12 h-12 rounded-full object-contain"
        />
        <div className="ml-4 flex-1">
          <p className="text-base">
            {item.productCode ? `${item.productCode} ` : ""}
            {item.productDescription}
          </p>
          <div className="flex justify-between items-center mt-2">
            <p className="text-xl font-semibold">{decimalToMoney(item.price)}</p>
            <div className="flex items-center justify-center border-2 border-lightblue w-8 h-8 rounded-full">
              <IoAddOutline className="text-lightblue w-7 h-7" />
            </div>
          </div>
        </div>
      </div>
    );
  });



  return (
    <div className="flex flex-col">
      <AppHeader />
      <Header title="Pesquisa de produtos" name={vendorRef.current.vendorName} />

      <div
        className="flex flex-col items-center bg-white"
        style={{ height: 'calc(100vh - 100px)' }}
      >
        <div
          className="flex flex-row justify-between mt-2 h-11 w-full max-w-[calc(100vw-20px)] border border-gray-400 rounded-full px-2.5 pt-1"
        >
          <input
            className="w-80 h-9 text-lg font-bold text-gray-400 bg-transparent outline-none"
            ref={textInputRef}
            autoFocus
            value={null}
            type="text"
            maxLength={50}
            onChange={(e) => setProductDescription(e.target.value)}
          />
          <IoSearchOutline className="w-7 h-7 text-gray-400" />
        </div>

        <div
          className="overflow-y-auto mt-1 ml-1.5"
          style={{ height: 'calc(100vh - 450px)' }}
        >
          <ul>
            {products.map((product) => (
              <li key={product.productID}>{renderItem({ item: product })}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Search;