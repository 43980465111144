import React, { useState, useEffect } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';
import { environment } from '../services/environment';
import ErrorPage from './errorPage';
import Spinner from './spinner';
import { addMonth, dateStampToDate, getDateStampLocal, addDays } from '../services/myFunctions';

const Charts = ({ vendorID, userToken }) => {
    const [downloading, setDownloading] = useState(false);
    const [data, setData] = useState([]);

    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const endDate = addDays(getDateStampLocal(),1);
    const startDate = addMonth(endDate, -5);

    const dbCall = () => {
        setDownloading(true);
        getChartData()
    }

    useEffect(() => {
        dbCall();
    }, [vendorID])

    const getChartData = () => {

        const toSearch = { "vendorID": vendorID, "startDate": startDate, "endDate": endDate };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, environment.timeoutValue);

        fetch(environment.centralUrl + 'GetChartData',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
                body: JSON.stringify(
                    toSearch
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }

                return response.json()
            })
            .then((json) => {

                setData(json);
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
                setDownloading(false);
            })
    }


    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
        dbCall()
    }

    return (
        <div className="flex justify-center p-1 w-full">
            <div className="bg-white shadow-lg rounded-lg p-3 w-full max-w-4xl">
                <h2 className="text-2xl font-bold mb-4">Resumo Pedidos - de {dateStampToDate(startDate)} até {dateStampToDate(endDate)}</h2>

                {/* First Chart: Qtd.Pedidos */}
                <h3 className="text-lg font-semibold mb-2">Quantidade de Pedidos</h3>
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="orderTS" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="qtdOrders" fill="#8884d8" name="Qtd.Pedidos" />
                    </BarChart>
                </ResponsiveContainer>

                {/* Second Chart: Total Valor */}
                <h3 className="text-lg font-semibold mb-2 mt-2">Total Pedidos R$</h3>
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="orderTS" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="totToPay" fill="#82ca9d" name="Total Valor" />
                    </BarChart>
                </ResponsiveContainer>

                <h3 className="text-lg font-semibold mb-2 mt-2">Valor Médio por Pedidos R$</h3>
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="orderTS" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="avgTicket" fill="#a132ce" name="Valor Médio por Pedido" />
                    </BarChart>
                </ResponsiveContainer>

                {/* Third Chart: Quantidade de Itens */}
                <h3 className="text-lg font-semibold mb-2 mt-2">Quantidade de Itens</h3>
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="orderTS" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="totalQuantity" fill="#ffc658" name="Qtd.Itens" />
                    </BarChart>
                </ResponsiveContainer>
            </div>

            {downloading && <Spinner />}
            <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
        </div>

    );
};

export default Charts;
