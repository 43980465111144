import React from 'react';
// import clientAppImg from '../images/clientapp.png'
// import googlePlay from '../images/google-play.png'
import { useNavigate } from 'react-router-dom';

import { IoPhonePortraitOutline } from 'react-icons/io5';

import qrCodeDownG from '../images/qrcode-down-g.png'
import qrCodeDownC from '../images/qrcode-down-c.png'

function Downloads() {
    const navigate = useNavigate();

    const goToAppHome = () => {
        navigate('/appHome');
    };


    return (
        <div className='m-5 '>

            <div>
                <div className="bg-gray-200 px-10 py-3 w-full ">
                    <h1 className="font-bold text-3xl font-raleway">Aplicativos</h1>
                    <div className="mt-10 flex flex-col md:flex-row md:space-x-20 w-full ">
                        <div className="flex flex-col w-full ">
                            <p className="text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                                Baixe o aplicativo
                            </p>
                            <p className="text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                                <span className='text-xl md:text-2xl'>AiGarçom Bar</span>.
                            </p>
                            <div className='flex w-full '>
                                <img
                                    src={qrCodeDownG}
                                    alt="qrCodeDownG"
                                    className="h-[250px]  hidden md:block"
                                />

                                <div className='block md:hidden flex w-full'>
                                    <div className='flex '>
                                        <a href="https://smartapplications.com.br/apk/aigarcom_g.apk">
                                            <button
                                                className={`mt-4 border bg-blue-500 text-white font-bold py-2 px-4 rounded`} >
                                                Baixar App
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-full">
                            <p className="mt-20 md:mt-0 text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                                Use o aplicativo
                            </p>
                            <p className="text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                                de autoatendimento.
                            </p>
                            <p className=" text-base md:text-xl font-bold text-gray-500 mb-2 font-raleway">
                                <span className='text-xl md:text-2xl'>AiGarçom Cliente</span>
                            </p>
                            <div className='flex w-full'>
                                <div className='flex items-center'>
                                    <button
                                        onClick={() => goToAppHome()}
                                        className="mt-4 border bg-blue-500 text-white font-bold py-2 px-4 rounded"
                                    >
                                        <div className='flex flex-row text-sm items-center'>
                                            <IoPhonePortraitOutline className="h-6 w-6 text-white" />
                                            App autoatendimento

                                        </div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default Downloads;