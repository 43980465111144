
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoArrowUndoCircleOutline, IoArrowRedoCircleOutline } from 'react-icons/io5';

import AppContext from '../services/AppContext';
import AppHeader from './appHeader';
import Header from './header';

import { decimalToMoney } from '../services/myFunctions';
import { environment } from '../services/environment';
import ErrorPage from './errorPage';
import { GetNewID } from '../services/data';
import Spinner from './spinner';

const saveLastOrder = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.log('Error saving data:', error);
    }
};

const PlaceOrder = () => {
    const navigate = useNavigate();
    const { orderID, orderToPlace, newOrder, loggedUser, vendorRef } = useContext(AppContext)
    const [downloading, setDownloading] = useState(false);
    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [observation, setObservation] = useState("");

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for some browsers to show the confirmation dialog
            
        };

        // Adding the event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (orderToPlace.current == null) {
            //garantir que seja zero caso seja null
            alert("Seu pedido já foi solicitado! Inicie um novo pedido")
            navigate("/");
            return;
        }
        setDownloading(true);
        getActiveOrderCli();

    }, [])

    const onBackToCart = () => {
        navigate('/cart');
    }

    const onConfirm = () => {

        saveOrder();
    }

    const getActiveOrderCli = () => {

        const toSearch = {
            "userID": loggedUser.current.userID,
            "vendorID": vendorRef.current.vendorID
        };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 10000);

        // console.log('getLocation loggedUser.current.userToken', loggedUser.current.userToken);

        fetch(environment.apiUrl + 'getActiveOrderCli',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loggedUser.current.userToken}`
                },
                body: JSON.stringify(
                    toSearch
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    // console.log("Error response", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }
                return response.json()
            })
            .then((json) => {

                if (json.orderID != "") {
                    //indicates that there are open orders for this client
                    orderToPlace.current.order.orderID = json.orderID;
                    orderToPlace.current.order.tabNumber = json.tabNumber;
                    orderToPlace.current.order.orderMessage = json.orderMessage;
                    orderToPlace.current.order.statusID = json.statusID;

                    //If IntheWay, Prepared on InProgress keep same status
                    if (json.statusID == "Delivered" ||
                        json.statusID == "") {
                        orderToPlace.current.order.statusID = "InProgress"
                    }

                    setObservation(json.orderMessage);
                }
                else {
                    orderToPlace.current.order.orderID = GetNewID();
                    orderToPlace.current.order.tabNumber = "";
                    orderToPlace.current.order.orderMessage = "";
                    orderToPlace.current.order.statusID = "InProgress";
                }
            })
            .catch((error) => {

                setErrorMessage(error.message);
                setErrModalVisible(true);
                // console.error(error);
            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
                setDownloading(false);
            })

    };

    const saveOrder = () => {

        //orderToPlace.current.order.orderMessage = observation;

        const toSave = orderToPlace.current;

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 5000);

        fetch(environment.apiUrl + 'saveOrderCli',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loggedUser.current.userToken}`
                },
                body: JSON.stringify(
                    toSave
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    // console.log("Error response", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }

                return response.json()

            }).then((json) => {

                //save order params that will be used in the tabinfo component
                const lastOrder = {
                    "orderID": orderToPlace.current.order.orderID,
                    "vendorID": orderToPlace.current.order.vendorID,
                    "userID": orderToPlace.current.order.userID
                }

                saveLastOrder("LastOrder", JSON.stringify(lastOrder));

                //Prepare for the next order
                orderToPlace.current = null;
                newOrder.current = true;
                //refreshOrders.current = true;

                navigate('/tabInfo');
                return;

            })

            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            })
            .finally(() => {
            })

    };


    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
        saveOrder()
    }

    return (
        <div className="flex flex-col h-screen">
            {orderToPlace.current != null &&
                <div>
                    <AppHeader />
                    <Header title="Confirmar Pedido" name={orderToPlace.current.order.vendorName} />
                </div>
            }

            {!downloading &&
                orderToPlace.current != null &&
                <div>
                    <div className="flex flex-row mx-2">
                        <div className="card my-2">
                            <div className="flex flex-row justify-between">
                                <p className="text-regular">Sua localização</p>
                            </div>
                            <div className="flex ">
                                <div>
                                    <p className="text-big font-bold">{orderToPlace.current.order.vendorName}</p>
                                    <p className="text-big font-bold">{orderToPlace.current.order.locationDescr}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between mx-2 mt-2 mb-2">
                        <div className="card">
                            <p className="text-regular">Qtd. Items</p>
                            <p className="text-big font-bold text-center">{orderToPlace.current.totalQuantity}</p>
                        </div>

                        <div className="card">
                            <p className="text-regular text-center">Total Pedido</p>
                            <p className="text-big font-bold text-center">
                                {decimalToMoney(orderToPlace.current.totToPay)}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-row mx-2">
                        <div className="card my-2">
                            <div className="flex flex-row">
                                <p className="text-regular">Forma de pagamento</p>
                            </div>
                            <div className="flex">
                                <p className="text-big font-bold">Pagar direto ao garçon ou no caixa</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mx-2 my-2 ">
                        <div className="flex flex-row">
                            <p className="text-regular">Observações (Opcional)</p>
                        </div>
                        <textarea
                            className="border rounded border-gray-400 p-2 text-input mt-2 h-24 w-[calc(100%-4px)]"
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                            placeholder="Sem cebola... com gelo e limão no copo ..."
                        />
                    </div>
                </div>
            }

            <div className="fixed bottom-2 w-[calc(100%-20px)] mx-2">
                <div className="flex flex-row justify-between">
                    <div className="border rounded-lg border-bgButton p-2">
                        <button onClick={onBackToCart} className="flex flex-row items-center">
                            <IoArrowUndoCircleOutline size={22} className="text-bgButton" />
                            <span className="text-regular-bold ml-2 text-bgButton">Voltar Carrinho</span>
                        </button>
                    </div>

                    <div className="border rounded-lg border-bgButton p-2 bg-bgButton">
                        <button onClick={onConfirm} className="flex flex-row items-center ">
                            <span className="text-regular-bold text-white mr-2">Confirmar Pedido</span>
                            <IoArrowRedoCircleOutline size={22} className="text-white" />
                        </button>
                    </div>
                </div>
            </div>

            <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
            {downloading && <Spinner/>}
        </div>
    );
};

export default PlaceOrder;
