import React from 'react';
import { IoCheckmarkOutline } from 'react-icons/io5';

const CurLocation = ({ vendorName, locationDescr }) => {

    return (
        <div>
            <div
                className="w-[350px] mx-2.5 mt-5 bg-green-500 rounded-lg p-3 flex flex-row items-center shadow-lg z-30"
            >
                <IoCheckmarkOutline className="w-7 h-7 text-white" />

                <div className="ml-3">
                    <p className="text-lg font-semibold text-white">
                        Sua localização atual
                    </p>
                    <p className="text-2xl font-semibold text-white">
                        {vendorName}
                    </p>
                    <p className="text-2xl font-semibold text-white">
                        {locationDescr}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CurLocation;
