import React from 'react';
import { useSpring, animated } from 'react-spring';
import { IoCheckmarkCircle, IoAlertCircle, IoInformationCircle, IoWarning } from 'react-icons/io5';

const ToastMessage = (props) => {
    const TOAST_TYPE = {
        success: {
            backgroundColor: '#2ecc71',
            icon: <IoCheckmarkCircle size={40} color="#FFF" />
        },
        danger: {
            backgroundColor: '#e74c3c',
            icon: <IoAlertCircle size={40} color="#FFF" />
        },
        info: {
            backgroundColor: '#3498db',
            icon: <IoInformationCircle size={40} color="#FFF" />
        },
        warning: {
            backgroundColor: '#f39c12',
            icon: <IoWarning size={40} color="#FFF" />
        }
    }

    const backgroundColor = TOAST_TYPE[props.type].backgroundColor;
    const icon = TOAST_TYPE[props.type].icon;

    const animationProps = useSpring({
        from: { opacity: 0, transform: 'translateY(-20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 300, friction: 20 }
    });

    return (
        <animated.div className="w-full h-[70px] bg-bgToast bg-opacity-90 rounded-lg p-3 flex items-center shadow-md z-30"
            style={{
                ...animationProps
            }}
        >
            {icon}

            <div className="ml-3">
                <p className="text-white font-semibold text-lg">{props.text}</p>
                <p className="text-white font-normal text-sm">{props.description}</p>
            </div>
        </animated.div>
    );
};

export default ToastMessage;
