import React, { memo, useEffect } from 'react';

import { decimalToMoney } from '../services/myFunctions';

const OrderItem = memo(function orderRow({ item,
    showStatus }) {

    return (
        <div key={item.orderItemsID} className="border-b-2 rounded-b-md mb-1">
            <div className="flex flex-row items-center bg-white pl-1">
                <div className="flex-[0.1] w-px h-12 bg-white"></div>
                <div className="flex-[1.5]">
                    <img
                        src={item.url}
                        alt="Product"
                        className="w-10 h-10 rounded-full object-contain"
                    />
                </div>

                <div className="flex-[10] m-2">
                    <p className="text-sm font-arial">
                        {item.productCode} {item.productDescription}
                    </p>

                    <div className="flex flex-row">
                        <div className="flex-3 text-left">
                            <p className="text-sm font-arial">
                                {decimalToMoney(item.price)}
                            </p>
                        </div>
                        <div className="flex-1 text-left">
                            <p className="text-sm font-arial ml-2">x</p>
                        </div>
                        <div className="flex-1 text-right">
                            <div className="flex flex-row">
                                {item.quantity !== item.quantityOriginal && (
                                    <div>
                                        <p className="font-bold  text-sm font-arial mr-1">
                                            {item.quantityOriginal}
                                        </p>
                                        <p className="absolute text-red-500 text-lg top-0 left-0">
                                            X
                                        </p>
                                    </div>
                                )}
                                <p className="text-sm font-arial">{item.quantity}</p>
                            </div>
                        </div>
                        <div className="flex-7 text-right">
                            <p className="font-bold text-sm font-arial">
                                {decimalToMoney(item.totPrice)}
                            </p>
                        </div>
                    </div>

                    {showStatus && (
                        <div className="flex flex-row">
                            <p className="text-sm font-arial font-bold ml-1">{item.statusDescription}</p>
                        </div>
                    )}
                    {item.itemObs !== "" && (
                        <div className="mr-2 flex justify-center">
                            <p className="text-red-500 text-sm font-arial">
                                {item.itemObs}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

});

export default OrderItem;
