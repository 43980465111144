
import React from 'react';
import { colors } from '../styles/AppStyles';
import { IoStorefrontOutline } from 'react-icons/io5';

const Header = ({title, name}) => {
    // console.log('props.name', props.name);

    if (name != undefined) {
    }
    return (

        <div
            className="w-screen pl-2 py-1 bg-bgTitle"
        >
            {name && (
                <div className="flex flex-row items-center">
                    <IoStorefrontOutline className='text-gray-600 w-5 h-5' />
                    <span className="ml-1 text-gray-600 font-rail">{name}</span>
                </div>
            )}
            <div className="flex ml-1 ">
                <span className="text-lg text-white font-rail-bold">{title}</span>
            </div>
        </div>
    );

};

export default Header;
