
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import AppContext from '../services/AppContext';
import { environment } from '../services/environment';

import CurLocation from './curLocation';

import AppHeader from './appHeader';
import Header from './header';

import ErrorPage from './errorPage';
import { getTimeStampLocal } from '../services/myFunctions';
import { IoArrowUndoOutline } from 'react-icons/io5';

//import { io } from 'socket.io-client';


const CallWaiter = () => {
    const navigate = useNavigate();
    const { loggedUser, locationRef } = useContext(AppContext);

    const location = useLocation();
    const { returnTo } = location.state || {}; // Acessando os parâmetros

    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [visible, setVisible] = useState(false);
    //const [userLocation, setUserLocation] = useState(null);
    const [notification, setNotification] = useState("");
    const [msgStatus, setMsgStatus] = useState("Sent");

    useEffect(() => {
        if (locationRef.current != null) {
            // setUserLocation({
            //     'vendorName': locationRef.current.vendorName,
            //     'userlocationDescr': locationRef.current.userlocationDescr
            // });

            onCallWaiter();

        }

    }, [])


    // useEffect(() => {
    //     const socket = io(environment.socketIoUrl);

    //     socket.on('connect', () => {
    //         socket.emit('storeSocket', loggedUser.current.userID, vendorRef.current.vendorID)
    //     });

    //     socket.on("Notification", (vendorID, userID, requestStatus) => {
    //         console.log("Callwaiter Notification vendorID, userID", vendorID, userID, requestStatus)
    //         if (vendorRef.current.vendorID == vendorID
    //             && loggedUser.current.userID == userID
    //             && requestStatus == "Closed"
    //         ) {
    //             setMsgStatus("Attended");
    //         }
    //     })

    //     return () => {
    //         socket.disconnect()
    //     };

    // }, [])

    const onCallWaiter = () => {

        if (locationRef.current == null) {
            alert("Nenhum estabelecimento foi selecionado! Leia o QR Code mais próximo para identificar o estabelecimento.")
            return;
        }

        const toSave = {
            "vendorID": locationRef.current.vendorID,
            "userID": loggedUser.current.userID,
            "locationDescr": locationRef.current.locationDescr,
            "requestDescr": notification == "" ? "Chamando garçom" : notification,
            "requestStatus": "Raised",
            "timeStamp": getTimeStampLocal()
        };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 5000);

        // console.log('getUserLocation loggedUser.current.userToken', loggedUser.current.userToken);

        fetch(environment.apiUrl + 'SendRequest',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loggedUser.current.userToken}`
                },
                body: JSON.stringify(
                    toSave
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    // console.log("Error response", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }
                return response.json()
            })
            .then((json) => {
                //console.log("UserLocations ####", json)

                if (notification.trim() != "") {
                    setMsgStatus("MsgSent");
                }
                else {
                    setMsgStatus("Sent");
                }

            })
            .catch((error) => {

                setErrorMessage(error.message);
                setErrModalVisible(true);
                // console.error(error);
            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
            })

    }

    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);

    }

    ////console.log("order status orderToPlace", orderToPlace.current);
    const onBack = () => {
        if (!returnTo) {
            navigate(-1)
        }
        else {
            navigate(`/${returnTo}`)
        }
    }

    return (
        <div>
            <AppHeader />
            {locationRef.current ? (
                <Header title="Chamar o garçom" name={locationRef.current.vendorName} />
            ) : (
                <Header title="Chamar o garçom" />
            )}

            <div className='w-screen h-screen'>
                {!visible && locationRef.current && (
                    <div className="flex justify-center w-screen">
                        <CurLocation
                            vendorName={locationRef.current.vendorName}
                            locationDescr={locationRef.current.locationDescr}
                        />
                    </div>
                )}

                {!visible && (
                    <div>
                        <div className='flex flex-col w-screen justify-center px-5'>
                            <div className="bg-white shadow-lg rounded-lg w-full mt-5 p-4">
                                <div className="my-5">
                                    {/* {msgStatus !== "Attended" ? ( */}
                                    <div>
                                        <p className="text-red-500 text-center">
                                            Seu pedido de atendimento foi enviado! Um garçom virá até você em breve.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='flex justify-center'>
                            <button className="mt-5" onClick={onBack}>
                                <div className="flex flex-row items-center border border-bgButton p-2 rounded-lg">
                                    <IoArrowUndoOutline className='w-7 h-t text-bgButton' />
                                    <div className="ml-2">
                                        <p className="font-semibold text-blue-500">
                                            Sair
                                        </p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <ErrorPage
                errorMessage={errorMessage}
                visible={errModalVisible}
                closeModal={closeErrModal}
                closeBtn={true}
                retryBtn={false}
            />
        </div>
    );
};

export default CallWaiter;
