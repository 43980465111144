import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode-svg';

const DisplayQRCode = ({ text, w=256, h=256 }) => {
  const [qrCodeData, setQrCodeData] = useState('');

  useEffect(() => {
    // Generate the QR code as SVG
    const qr = new QRCode({
      content: text, // Text or URL you want to encode
      width: w,    // QR code width
      height: h,   // QR code height
      color: "#000000", // QR code color
      background: "#ffffff", // QR code background color
    });
    setQrCodeData(qr.svg()); // Get the QR code as SVG string
  }, [text]);

  return (
    <div dangerouslySetInnerHTML={{ __html: qrCodeData }} />
  );
};

export default DisplayQRCode;