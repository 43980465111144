import React, { useEffect, useContext, useRef } from 'react';
import AppContext from '../services/AppContext';
import { useNavigate } from 'react-router-dom';
import { IoPersonOutline } from 'react-icons/io5';
import { environment } from '../services/environment';

const AppHeader = () => {
    const { loggedUser, userNameHeader, setUserNameHeader } = useContext(AppContext);
    const userEmailRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("appHeader entered useEffect");
        if (!userNameHeader) {

            console.log("appHeader fecth user");
            
            const userEmail = localStorage.getItem('cliUserEmail');

            if (!userEmail) {
                // console.log("userID é branco");
                navigate("/createUser");
            }
            else {
                userEmailRef.current = userEmail;
                // console.log("getUserdata", userEmail)
                // console.log("getUserdata", userEmailRef.current)
                userLogin()
            }
        }


    }, [userNameHeader])

    useEffect(() => {


        if (userEmailRef.current) {
            userLogin();
        }
    }, [userEmailRef.current])

    const userLogin = () => {
        const userData = {
            "UserEmail": userEmailRef.current,
            "UserPwd": userEmailRef.current.substring(0, 8),
        };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 10000);


        fetch(environment.apiUrl + "UserLogin", {
            method: 'POST',

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${environment.preUser}`
            },
            body: JSON.stringify(userData),
            signal,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }
                return response.json();
            })
            .then((json) => {

                loggedUser.current.userID = json.UserID;
                loggedUser.current.userName = json.UserName;
                loggedUser.current.userEmail = json.UserEmail;
                loggedUser.current.userRole = json.userRole;
                loggedUser.current.isEmailVerified = json.IsEmailVerified;
                loggedUser.current.userToken = json.UserToken;
                loggedUser.current.verificationCode = json.VerificationCode;

                if (json.Validated === "OK") {
                    setUserNameHeader(json.UserName);
                } else {
                    alert("Usuário ou senha inválidos!");
                }
            })
            .catch((error) => {
            });
    };


    return (
        <div className="flex flex-row h-10 pt-1 pl-2 bg-bgTitle">
            <div className="flex flex-row basis-1/2">
                <div className="shrink-0 space-x-1" >
                    <img
                        src="https://smartapplications.com.br/images/logoaigarcom2.png"
                        alt="Logo"
                        className="w-32"
                    />
                </div>
                <div className="text-xs">
                    <p>v1.0</p>
                </div>
            </div>

            <div className="basis-1/2" >

                <div className='flex flex-row justify-end mr-3'>
                    <IoPersonOutline className='text-white w-5 h-5' />

                    <p className="text-sm ml-3 text-white">{userNameHeader}</p>
                </div>
            </div>
        </div>
    );
};

export default AppHeader;
