import { v4 as uuidv4 } from 'uuid';

// const LoggedUser = { "displayName": null, "email": "Fazer Login", "emailVerified": false, "isAnonymous": false, "metadata": { "creationTime": 1647105575325, "lastSignInTime": 1647105575325 }, "phoneNumber": null, "photoURL": null, "providerData": [[Object]], "providerId": "firebase", "tenantId": null, "uid": null }
const LoggedUser = {
  "userID": "",
  "userName": "",
  "email": "Fazer Login",
  "userRole": "",
  "isEmailVerified": "0",
  "isAnonymous": "0",
  "userToken": "",
  "verificationCode": ""
}

// const Session = { "UniqueID": null, "LoggedUser": LoggedUser};


const GetNewID = () => {
  return uuidv4();
}

const splitQRCodeInfo = (qrCodeInfo) => {
  const splitUrlParams = qrCodeInfo.split('?');
  const downloadUrl = splitUrlParams[0];

  const splitParams = splitUrlParams[1].split('&');
  const vendorID = splitParams[0].split('=')[1];
  const locationID = splitParams[1].split('=')[1];

  //console.log("URL, Vendor, location", downloadUrl,vendorID,locationID);

  return { 'downloadUrl': downloadUrl, 'vendorID': vendorID, 'locationID': locationID }
}

const Order = null;

// {
//   "productID": "001" , 
//   "quantity" : 1, "price":0.00  
// }

const calcOrderTotals = (order) => {
  const orderItems = order.orderItems;
  var totalQuantity = 0;
  var totToPay = 0;
  orderItems.map((item) => {

    totalQuantity += item.quantity;
    totToPay += item.price;

  })

  order.totalQuantity = totalQuantity;
  order.totToPay = totToPay;

  return { "order": order };
};

const VendorRef = {
  "vendorID": "",
  "vendorName": "",
  "vendorCPFCNPJ": ""
}

const VendorUserRef = {
  "vendorID": "",
  "vendorName": "",
  "vendorCPFCNPJ": "",
  "userEmail": ""
}

const TabRef = {
  "tabNumber": "",
  "orderID": "",
  "statusID": ""
}

const calcOrderItemTotals = (order, productID) => {
  const orderItems = order.orderItems;
  var totalQuantity = 0;
  var itemQuantity = 0;
  var totToPay = 0;
  orderItems.map((item) => {
    if (productID == item.productID) {
      itemQuantity += item.quantity
    }

    totalQuantity += item.quantity;
    totToPay += item.price * item.quantity;

  })

  order.itemQuantity = itemQuantity;
  order.totalQuantity = totalQuantity;
  order.totToPay = totToPay;

  return { "order": order };
};

const getUniqueOrderItems = (orderItems) => {
  var newOrderItems = [];

  orderItems.map((item) => {

    const i = newOrderItems.findIndex(row => item.productID === row.productID);

    if (i == -1) {
      //insert item
      const toSave = {
        "orderID": item.orderID, "productID": item.productID,
        "quantity": item.quantity, "price": item.price, "totPrice": item.price * item.quantity,
        "productDescription": item.productDescription, "productCode": item.productCode,
        "url": item.url, "itemObs": item.itemObs
      };
      newOrderItems.push(toSave);
    }
    else {
      //update totals
      newOrderItems[i].quantity += item.quantity;
      newOrderItems[i].totPrice += item.price * item.quantity
    }
  })

  ////console.log(newOrderItems);

  return newOrderItems;
}



export { GetNewID, splitQRCodeInfo, calcOrderTotals, calcOrderItemTotals, Order, getUniqueOrderItems, LoggedUser, TabRef, VendorRef, VendorUserRef };


