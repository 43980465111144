
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoCheckboxOutline, IoArrowUndoCircleOutline, IoBagAddOutline, IoQrCode, IoWalletOutline, IoNotificationsOutline } from 'react-icons/io5';

import AppHeader from './appHeader';
import Header from './header';
import { decimalToMoney, getLocalDateTime } from '../services/myFunctions';
import { environment } from '../services/environment';
import AppContext from '../services/AppContext';
import ErrorPage from './errorPage';
import OrderItem from './orderItem';
import { splitQRCodeInfo } from '../services/data';
import QRScan from "./qrScan";
import Spinner from './spinner';
import ToastMessage from './ToastMessage';

import { io } from 'socket.io-client';

const saveDataOffline = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.log('Error saving data:', error);
    }
};


const TabInfo = () => {
    const navigate = useNavigate();
    const { orderToPlace, loggedUser, vendorRef, locationRef, qrCodeInfoRef } = useContext(AppContext);
    const [AllItemsList, setAllItemsList] = useState([]);
    const [totals, setTotals] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);

    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [qrCodeInfo, setQrCodeInfo] = useState(qrCodeInfoRef.current);
    const nextAction = useRef("");
    const [visible, setVisible] = useState(false);

    const [vendorName, setVendorName] = useState("");

    const tabNumberRef = useRef("");

    const paymentRef = useRef(null);
    const lastOrderRef = useRef(null);

    //localStorage.removeItem("Offline");

    const getLastOrder = () => {
        try {
            setDownloading(true);

            const value = localStorage.getItem("LastOrder");

            if (value !== null) {
                const json = JSON.parse(value)
                lastOrderRef.current = json;

                getTab();
            }
            else {
                resetOrder();
            }
        } catch (error) {
            // console.log('Error retrieving data:', error);
        } finally {
            setDownloading(false);

        }
    };

    const resetOrder = () => {
        setVendorName("");

        lastOrderRef.current = null;

        vendorRef.current = {
            vendorID: "",
            vendorName: "",
            vendorCPFCNPJ: ""
        };
        //used in the context
        locationRef.current = null;

        tabNumberRef.current = "";
        //console.log("json.Payment", json.Payment)

        paymentRef.current = null;

        setAllItemsList([]);

        //console.log('Retrieved data failed', value);
        setTotals({ "totalQuantity": 0, "qtdorders": 0, "totToPay": 0, "statusID": "" })

    }
    useEffect(() => {
        dbCall();
    }, [loggedUser.current.userName])

    const dbCall = () => {
        if (!errModalVisible) {
            setDownloading(true);
            getLastOrder();
        }
    }

    useEffect(() => {
        // //used to save in the context
        // qrCodeInfoRef.current = qrCodeInfo;

        qrCodeInfoRef.current = qrCodeInfo;

        if (qrCodeInfo != null) {
            if (qrCodeInfo.vendorID !== vendorRef.current.vendorID) {
                try {
                    localStorage.removeItem("LastOrder");
                } catch (error) {
                    console.log('Error saving data:', error);
                }
                
                resetOrder();
            }
            getLocation();
        }

    }, [qrCodeInfo]);

    useEffect(() => {

        if (vendorRef.current.vendorID !== "") {

            // console.log("Passou do if", totals.vendorID);

            const socket = io(environment.socketIoUrl);

            socket.on('connect', () => {
                socket.emit('storeSocket', loggedUser.current.userID, vendorRef.current.vendorID)
            });

            socket.on("statusUpdated", (orders) => {
                if (lastOrderRef.current !== null) {
                    const i = orders.findIndex(order=>order.orderID == lastOrderRef.current.orderID)
                    if (i>-1) {
                        const timer = setTimeout(() => {
                            clearTimeout(timer);
                            setDownloading(true);
                            getTab()
                        }, 1000)
                    }
                }

            })

            return () => {
                socket.disconnect()
            };

        }

    }, [vendorRef.current.vendorID])

    const onTest = () => {
        nextAction.current = "OpenMenu";
        setQrCodeInfo(splitQRCodeInfo('https://aigarcon.com.br?v=4a07f060-f7ba-4873-95a1-1af50fcb7bfe&l=0cd0b151-75f0-11ef-b287-a21815963ae5'));

    };


    const onOpenMenu = () => {
        if (locationRef.current !== null && vendorRef.current !== null) {

            if (orderToPlace.current == null) {
                registerOrder();
            } else if (vendorRef.current.vendorID !== orderToPlace.current.order.vendorID) {
                alert("Ops! Detectamos que você trocou de estabelecimento, um novo pedido está sendo gerado!");
                registerOrder();
            }

            navigate('/menu');
        }
        else {
            nextAction.current = "OpenMenu";
            setVisible(true);
        }
    };

    const registerOrder = () => {
        const clientName = loggedUser.current.userName.split(" ")[0];
        const order = {
            orderID: "",
            userID: loggedUser.current.userID,
            clientName,
            vendorID: vendorRef.current.vendorID,
            vendorName: vendorRef.current.vendorName,
            locationID: locationRef.current.locationID,
            locationDescr: locationRef.current.locationDescr,
            orderDT: getLocalDateTime(),
            statusID: "",
            tabNumber: ""
        };

        orderToPlace.current = {
            order,
            totalQuantity: 0,
            totToPay: 0,
            orderItems: []
        };
    };

    // const onOffline = () => {
    //   navigate('/offline');
    // };

    const onCallWaiter = () => {
        nextAction.current = "CallWaiter";
        if (locationRef.current !== null && vendorRef.current !== null) {
            navigate("/callWaiter");
        } else {
            setVisible(true);
        }
    };

    const handleScanSuccess = ((decodedText) => {
        setQrCodeInfo(decodedText);
    })

    const getLocation = () => {

        setDownloading(true);

        const toSearch = {
            userID: loggedUser.current.userID,
            vendorID: qrCodeInfo.vendorID,
            locationID: qrCodeInfo.locationID
        };

        fetch(`${environment.apiUrl}getLocation`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedUser.current.userToken}`
            },
            body: JSON.stringify(toSearch)
        })
            .then(response => {
                if (!response.ok) throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                return response.json();
            })
            .then(json => {
                vendorRef.current = {
                    vendorID: json.vendorID,
                    vendorName: json.vendorName,
                    vendorCPFCNPJ: json.vendorCPFCNPJ
                };
                //used in the context
                locationRef.current = json;
                setVendorName(json.vendorName);

                if (nextAction.current === "OpenMenu") {
                    onOpenMenu();
                } else if (nextAction.current === "CallWaiter") {
                    navigate("/callWaiter", { state: { "returnTo": "appHome" } });
                }

            })
            .catch(error => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            }).finally(() => {
                setDownloading(false);
            }
            );
    };


    // useEffect(() => {
    //     const unsubscribe = navigation.addListener('state', (e) => {
    //         previousScreen.current = e.data.state.routes[e.data.state.index - 1]?.name;
    //     });

    //     return unsubscribe;
    // }, [navigation]);



    function onOpenPayment() {
        navigate('/paymentPixOpen', {
            "pixChave": paymentRef.current.pix_chave,
            "paymentID": paymentRef.current.paymentID,
            "invoiceDate": paymentRef.current.invoiceDate,
            "paymentValue": paymentRef.current.paymentValue,
            "paymentStatus": paymentRef.current.paymentStatus,
            "paymentDate": paymentRef.current.paymentDate
        });
    }

    const onBack = () => {
        //navigation.goBack();
        navigate("/");
    }

    const getTab = () => {

        //Send the vendor in order to check if user has select another vendor
        // in this case last order will be displayed only if selected vendor is the same 
        // as the one in the last order

        if (loggedUser.current.userID === "") return;
        if (!lastOrderRef.current) return;

        const toSearch = {
            "userID": loggedUser.current.userID,
            "orderID": lastOrderRef.current.orderID,
            "vendorID": lastOrderRef.current.vendorID
        };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 5000);

        fetch(environment.apiUrl + 'getTabCli',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loggedUser.current.userToken}`
                },
                body: JSON.stringify(
                    toSearch
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error response ", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }

                return response.json()
            })
            .then((json) => {
                //console.log("json", json.Totals);
                tabNumberRef.current = json.Totals.tabNumber;
                //console.log("json.Payment", json.Payment)

                paymentRef.current = json.Payment;

                setTotals(json.Totals);
                setAllItemsList(json.AllItems);

                if (json.Totals.totalQuantity > 0) {

                    vendorRef.current = {
                        vendorID: json.Totals.vendorID,
                        vendorName: json.Totals.vendorName,
                        vendorCPFCNPJ: json.Totals.vendorCPFCNPJ
                    };
                    //used in the context
                    locationRef.current = {
                        vendorID: json.Totals.vendorID,
                        vendorName: json.Totals.vendorName,
                        locationID: json.Totals.locationID,
                        locationDescr: json.Totals.locationDescr,
                    };

                    setVendorName(json.Totals.vendorName);

                    //console.log("Data saved off-line")
                    saveDataOffline("Offline", JSON.stringify(json))
                }
                else {
                    resetOrder();
                }

            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);
            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
                setDownloading(false);
            })
    }

    const retry = () => {
        setErrModalVisible(false);
        dbCall()
    }

    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const renderItem = (item) => {
        //console.log("renderItem @@@@@@@@@@", item.orderItemsID)
        return (
            <OrderItem 
                item={item} showStatus={(totals.statusID == "Payed") ? false : true}
            />
        )
    }

    const renderEmptyComponent = () => {
        return (
            <div className="flex flex-col  items-center">
                <p className="font-bold text-sm text-arial mt-5">
                    Para iniciar seu pedido e
                </p>
                <p className="font-bold text-sm text-arial mt-5">
                    incluir novos items
                </p>
                <p className="font-bold text-sm text-arial mt-5">
                    pressione o botão "Incluir itens" acima.
                </p>
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen justify-start">
            <AppHeader />
            <Header title="Acompanhar meu pedido" name={vendorName} />

            {totals && (
                <div className="w-full flex flex-col mt-1 items-center">
                    <div className="w-[calc(100%-20px)]">
                        <div className="flex flex-row ">
                            <div className="flex-1 text-center">
                                <p className="text-sm font-arial">Comanda</p>
                            </div>
                            <div className="flex-1 text-center">
                                <p className="text-sm font-arial">Localização</p>
                            </div>
                            <div className="flex-1 text-center">
                                <p className="text-sm font-arial">Itens</p>
                            </div>
                            <div className="flex-1 text-center">
                                <p className="text-sm font-arial">Total</p>
                            </div>
                        </div>

                        <div className="flex flex-row ">
                            <div className="flex-1 text-center">
                                <p className="font-bold text-sm font-arial">
                                    {totals.tabNumber === '' ? 'Não' : totals.tabNumber}
                                </p>
                            </div>
                            <div className="flex-1 text-center">
                                <p className="font-bold text-sm font-arial">{totals.locationDescr}</p>
                            </div>
                            <div className="flex-1 text-center">
                                <p className="font-bold text-sm font-arial">{totals.totalQuantity}</p>
                            </div>
                            <div className="flex-1 text-center">
                                <p className="font-bold text-sm font-arial">{decimalToMoney(totals.totToPay)}</p>
                            </div>
                        </div>
                        {toastVisible && (
                            <div className="ml-5">
                                <ToastMessage type="success" text="Bem vindo!" description={vendorName} />
                            </div>
                        )}

                        <div className='flex w-[calc(100%)] h-[75px] justify-center space-x-2 mt-3'>
                            <button onClick={onOpenMenu}>
                                <div className='flex flex-col items-center p-1 w-[82px] h-full rounded-lg border border-bgButton'>
                                    <IoBagAddOutline className="w-5 h-5 text-bgButton" />
                                    <div className='text-bgButton text-sm font-arial text-center'>Incluir</div>
                                    <div className='text-bgButton text-sm font-arial text-center'>Itens</div>
                                </div>
                            </button>
                            <button onClick={onCallWaiter}>
                                <div className='flex flex-col items-center p-1 w-[82px] h-full rounded-lg border border-bgButton'>
                                    <IoNotificationsOutline className="w-5 h-5 text-bgButton" />
                                    <div className='text-bgButton text-sm font-arial text-center'>Chamar Garçom</div>
                                </div>
                            </button>
                            <button onClick={() => setVisible(true)}>
                                <div className='flex flex-col items-center p-1 w-[82px] h-full rounded-lg border border-bgButton'>
                                    <IoQrCode className="w-5 h-5 text-bgButton" />
                                    <div className='text-bgButton text-sm font-arial text-center'>Alterar Localização</div>
                                </div>
                            </button>
                            {/* {paymentRef.current ?
                                <div className='flex flex-col items-center p-1 w-[82px] h-full rounded-lg border border-bgButton'>
                                    <IoWalletOutline className="w-5 h-5 text-bgButton" />
                                    <div className='text-bgButton text-sm font-arial text-center'>Ver Pagamento</div>
                                </div> :
                                <div className='flex flex-col items-center p-1 w-[82px] h-full rounded-lg border border-gray-400'>
                                    <IoWalletOutline className="w-5 h-5 text-gray-600" />
                                    <div className='text-gray-600 text-sm font-arial text-center'>Ver Pagamento</div>
                                </div>
                            } */}
                        </div>

                        <div className="flex flex-row mt-2 justify-between items-center">
                            <div className="flex flex-row items-center">
                                <div
                                    className="w-4 h-4 rounded-full"
                                    style={{ backgroundColor: totals.statusColor, opacity: 0.7 }}
                                />
                                <p className="font-bold font-arial text-sm ml-2">{totals.statusDescription}</p>
                            </div>
                            <div className="flex flex-row items-center">
                                <button onClick={onTest} className="flex flex-row items-center">
                                    <IoCheckboxOutline className='w-7 h7 text-blue-400' />
                                    <p className="font-bold ml-2 text-center text-blue-400" >
                                        Loja teste
                                    </p>
                                </button>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-row justify-between items-center mt-2 w-[calc(100%-20px)] bg-blue-300 rounded-t-lg p-1">
                        <p className="text-sm ml-2 text-light">Itens Pedidos</p>
                    </div>

                    <div className="w-full p-2">
                        <div className="h-[360px] overflow-y-auto">
                            {AllItemsList.length > 0 ? (
                                AllItemsList.map((item) => renderItem(item))
                            ) : (
                                renderEmptyComponent()
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="fixed bottom-6 w-full flex justify-center">
                <button onClick={onBack}
                    className="flex flex-row justify-center items-center py-2 px-4 border rounded-lg border-bgButton">
                    <IoArrowUndoCircleOutline className='w-7 h7 text-blue-400' />
                    <p className="font-bold text-sm font-arial ml-2 text-center text-bgButton" >
                        Sair
                    </p>
                </button>
            </div>

            <ErrorPage
                errorMessage={errorMessage}
                visible={errModalVisible}
                closeModal={closeErrModal}
                retry={retry}
            />

            {downloading && <Spinner />}

            <QRScan visible={visible} setVisible={setVisible} setQrCodeInfo={setQrCodeInfo} />

        </div>
    );
};


export default TabInfo;
