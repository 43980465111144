import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from './appHeader';
import AppContext from '../services/AppContext';
import Spinner from './spinner';

const AppHome = () => {
  const navigate = useNavigate();
  const {  loggedUser} = useContext(AppContext);

  useEffect(()=>{

    if (loggedUser.current.userID !== ""){
      navigate("/tabInfo")
    }

  },[])

  return (
    <div>
      <AppHeader />
      <Spinner/>
      {console.log("Navigate to tabinfo")}
      {navigate("/tabInfo")}
    </div>
  );
};

export default AppHome;

