import React, { useContext, useEffect, useState } from 'react';
import YouTubeEmbed from './youTubeEmbed';


const Documents = () => {
    const [selVideoID, setSelVideoID] = useState("eIqhRuwpnh4");

    const videos = [
        { "title": "Abrindo o aplicativo AiGarçom", "videoID": "eIqhRuwpnh4" },
        { "title": "Dando entrada em um pedido", "videoID": "CDmdOACEtnU" },
        { "title": "Adicionando itens em um pedido", "videoID": "yilbnh2Uv54" },
        { "title": "Pesquisando itens", "videoID": "w6RuH9Vxw3E" },
        { "title": "Adicionando observações", "videoID": "w6RuH9Vxw3E" },
        { "title": "Excluindo itens", "videoID": "vQjZIH3a_U4" },
        { "title": "Marcando itens como entregue", "videoID": "Nwix2sN6y2k" },
        { "title": "Corrigindo o status de um item", "videoID": "J2GPi1k_b4A" },
        { "title": "Buscando comanda pelo número", "videoID": "yHxH5wzmyUU" },
        { "title": "Alterando localização do cliente", "videoID": "p-guK7tq2MI" }
    ]

    // {"Alterando observação":""},
    //     {"Alterando qtd/cancelando itens":""},

    const onSelect = (video) => {
        setSelVideoID(video.videoID);
    }
    return (
        <div className='m-5 '>
            <h1 className="font-bold font-base font-raleway">Documentação</h1>
            <div className='font-raleway'>Escolha o assunto na lista abaixo para ver um vídeo com instruções passo a passo</div>
            <div className="overflow-x-auto py-4 ">
                <div className="flex space-x-4">
                    {videos.map((video, index) => (
                        <div onClick={() => onSelect(video)}
                            key={index}
                            className={`${video.videoID==selVideoID?"bg-orange-200":"bg-white"}  border border-orange-300 rounded-xl px-2 py-1 flex-shrink-0`}
                        >
                            <h2 className="text-sm font-raleway">{video.title}</h2>
                        </div>
                    ))}
                </div>
            </div>

            <div className=' mt-10 w-full h-screen'>
                <YouTubeEmbed videoId={selVideoID} />
            </div>
        </div>
    )
};

export default Documents;