import React, { useEffect, useState } from 'react';
import UserVendors from "./userVendors";
import { useNavigate } from 'react-router-dom';

function PrintStation({ loggedUser, title, pageToPrint }) {

    const [vendorID, setVendorID] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {

        if (loggedUser) {
            setVendorID(loggedUser.vendorID);

        }
    }, [loggedUser])

    const onVendorSelected = (item) => {
        //console.log("Vendor Selected", item);

        setVendorID(item.vendorID);

        navigate("/" + pageToPrint, {state: {"vendorID": item.vendorID, "vendorName":item.vendorName, "userToken": loggedUser.userToken}})

    }

    return (
        <div className='ml-3'>
            <h1 className="font-bold font-base font-raleway">{title}</h1>
            <div className='flex flex-row space-x-5'>

                <div className='flex w-1/5' >

                    {loggedUser &&
                        <UserVendors
                            userID={loggedUser.userID}
                            onVendorSelected={onVendorSelected}
                            selectedVendorID={vendorID} />
                    }
                </div>

            </div>
        </div>

    );
}

export default PrintStation;