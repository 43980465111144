import React, { useState, useRef, useEffect, useContext, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { decimalToMoney } from '../services/myFunctions';
import { environment } from '../services/environment';

import AppContext from '../services/AppContext';
import AppHeader from './appHeader';
import Header from './header';
import ToastMessage from './ToastMessage';
import Spinner from './spinner';

import { IoAddOutline, IoSearchOutline, IoCartOutline, IoArrowUndoCircleOutline, IoArrowRedoCircleOutline } from 'react-icons/io5';

import { calcOrderItemTotals } from '../services/data';
import ErrorPage from './errorPage';

const Menu = () => {
    const { orderToPlace, loggedUser, vendorRef } = useContext(AppContext);
    const [isVisible, setIsVisible] = useState(false);
    const [curItem, setCurItem] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [activeItem, setActiveItem] = useState(0);
    const sectionsRef = useRef(null);
    const [sectionToDisplay, setSectionToDisplay] = useState([]);
    const [allProducts, setAllProducts] = useState([{"title": "", "data":[]}]);

    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for some browsers to show the confirmation dialog
            
        };

        // Adding the event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {

        //console.log('useEffect')
        dbCall();

        if (orderToPlace.current){
            setTotalQuantity(orderToPlace.current.totalQuantity);
        }

    }, [])

    //   useFocusEffect(() => {
    //     ////console.log("Menu useFocusEffect",orderToPlace)
    //     if (orderToPlace.current == null) {
    //       alert("Escolha um estabeleicimento! Leia o QR Code!")
    //       RootNavigation.navigate("Home");
    //       return;
    //     }

    //     setTotalQuantity(orderToPlace.current.totalQuantity);

    //   })

    const dbCall = () => {
        setDownloading(true);
        getProducts();
    }

    const getProducts = () => {

        if (vendorRef.current.vendorID == ""){
            navigate("/tabInfo");
        }

        const toSearch = { "VendorID": vendorRef.current.vendorID };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 10000);


        fetch(environment.apiUrl + 'getProducts',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loggedUser.current.userToken}`
                },
                body: JSON.stringify(
                    toSearch
                ),
                signal
            })
            .then((response) => {
                if (!response.ok) {
                    // console.log("Error response", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }
                return response.json()
            })
            .then((json) => {
                setProducts(json.Sections);
                sectionsRef.current = json.Sections;
                setCategories(json.Categories)
                setSectionToDisplay([json.Sections[0]]);
                setAllProducts(json.AllProducts)
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);

            })
            .finally(() => {
                ////console.log('End AdsSearchResult')
                setDownloading(false);
            })

    };

    const registerOrderItems = (item, quantity = 1) => {
        // setsaving(true);
        setIsVisible(true);

        const toSave = {
            "orderID": orderToPlace.current.order.orderID, "productID": item.productID,
            "quantity": quantity, "price": item.price, "totPrice": item.price * quantity,
            "productDescription": item.productDescription,
            "productCode": item.productCode, "url": item.url, "itemObs": ""
        };

        // orderToPlace.current.orderItems.push(toSave);

        const i = orderToPlace.current.orderItems.findIndex(row =>
            row.productID === item.productID
        )

        if (i == -1) {
            orderToPlace.current.orderItems.push(toSave);
        }
        else {
            orderToPlace.current.orderItems[i].quantity = orderToPlace.current.orderItems[i].quantity + quantity
        }

        calcOrderItemTotals(orderToPlace.current, item.productID)

        setTotalQuantity(orderToPlace.current.totalQuantity);
        setCurItem(item.productDescription + ' x ' + orderToPlace.current.itemQuantity);

        const timer = setTimeout(() => {
            clearTimeout(timer);
            setIsVisible(false);
            setCurItem('');
        }, 2000)

    };

    const onOpenCart = () => {
        //console.log('Menu cart', cartItems.current);

        // navigate('/Cart');
    }

    //   const onOpenDetails = (item) => {
    //     setModalVisible(true);
    //     setQuantityModalItem(1);
    //     setModalItem(item);

    //   }

    const onAddToCart = (item, quantity = 1) => {

        registerOrderItems(item, quantity);

        setModalVisible(false);

    }

    const onPressCategory = (item, section) => {

        setActiveItem(section);

        setSectionToDisplay([sectionsRef.current[section]])

    };

    const onSearch = () => {
        navigate("/search", {state:{ "params_products": allProducts }});
    }

    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
        dbCall()
    }

    const onBack = () => {
        navigate("/appHome");
    }
    const onConfirm = () => {
        if (orderToPlace.current == null) {
            //garantir que seja zero caso seja null
            alert("Seu carrinho está vazio! Escolha seus items no cardápio")
            return;
        } else if (orderToPlace.current.totalQuantity == 0) {
            //garantir que seja zero caso seja null
            alert("Seu carrinho está vazio! Escolha seus items no cardápio")
            return;
        }
        
        navigate("/cart");
    }

    const renderItem = (item) => {
        return (
            <MenuItem item={item} />
        )
    }

    const MenuItem = memo(function row({ item }) {
        return (
            <div
                onClick={() => onAddToCart(item)}
                className="flex items-center m-1 p-2 bg-white shadow-md rounded-lg"
            >
                <img
                    src={item.url}
                    alt={item.productDescription}
                    className="w-12 h-12 rounded-full object-contain"
                />
                <div className="ml-4 flex-1">
                    <p className="text-base">
                        {item.productCode ? `${item.productCode} ` : ""}
                        {item.productDescription}
                    </p>
                    <div className="flex justify-between items-center mt-2">
                        <p className="text-xl font-semibold">{decimalToMoney(item.price)}</p>
                        <div className="flex items-center justify-center border-2 border-lightblue w-8 h-8 rounded-full">
                            <IoAddOutline className="text-lightblue w-7 h-7" />
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    const renderCategory = (category, index) => {
        return (
            <button key={index} onClick={() => onPressCategory(category, index)}>
                <div
                    className={`${activeItem === index ? 'bg-bgTitle' : 'bg-gray-200'
                        } mx-1 rounded-full border border-gray-200 px-2 w-[100px] min-h-[40px] flex items-center justify-center`}
                >
                    <span
                        className={`${activeItem === index ? 'text-white' : 'text-gray-800'
                            }`}
                    >
                        {category}
                    </span>
                </div>
            </button>
        );
    };

    return (
        <div className="w-screen h-screen">
            <AppHeader />
            <div>
                <div className="flex flex-row">
                    {vendorRef.current && <Header title="Cardápio" name={vendorRef.current.vendorName} />}
                    <div
                        className="absolute right-3 w-[100px]"
                    >
                        <button onClick={onSearch}>
                            <div className="flex flex-row items-end">
                                <IoSearchOutline className="w-5 h-5 text-white" />
                                <span className="text-white ml-2">Pesquisar</span>
                            </div>
                        </button>
                    </div>
                </div>

                <div className="pt-2">
                    <div className="flex flex-row">
                        {!isVisible && (
                            <div className="relative bg-bgButton rounded p-2 ml-4 w-36">
                                {totalQuantity > 0 && (
                                    <div className="absolute -top-2 -right-2 text-center w-6 h-6 bg-orange-500 rounded-full">
                                        <span className="text-white">{totalQuantity>0?totalQuantity:""}</span>
                                    </div>
                                )}
                                <button onClick={onOpenCart}>
                                    <div className="flex flex-row items-center">
                                        <IoCartOutline className="w-7 h-7 text-white text-left" />
                                        <span className="text-white ml-2">Carrinho de compras</span>
                                    </div>
                                </button>
                            </div>
                        )}

                        {isVisible && (
                            <div className="ml-5">
                                <ToastMessage type="success" text="Incluido no carrinho!" description={curItem} />
                            </div>
                        )}
                    </div>
                </div>

                {!downloading && (
                    <div className="mt-3">
                        <div className="pl-2">
                            <div className="overflow-x-auto custom-scrollbar" >
                                <style>
                                    {`.custom-scrollbar::-webkit-scrollbar {display: none;}`}
                                </style>
                                <div className="flex space-x-2">
                                    {(() => {
                                        let i = 0;  // Initialize the counter
                                        return categories.map((item) =>
                                            renderCategory(item.category, i++)  // Pass category and increment counter
                                        );
                                    })()}
                                </div>
                            </div>
                        </div>

                        <div className='h-[430px] overflow-y-auto'>
                            {sectionToDisplay.length> 0 && <div className="p-1">
                                {sectionToDisplay.map((section) => (
                                    <div key={section.title}>
                                        {section.data.map((item) => renderItem(item))}
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                )}
            </div>

            <div
                className="fixed bottom-2 w-full p-2"
            >
                <div className="flex justify-between">
                    <div className="flex items-center justify-center p-1 w-36 bg-transparent border border-blue-600 rounded-lg">
                        <button onClick={onBack}>
                            <div className="flex flex-row justify-center items-center">
                                <IoArrowUndoCircleOutline className="w-7 h-7 text-blue-400" />
                                <span className="text-blue-400 ml-2">Home</span>
                            </div>
                        </button>
                    </div>
                    <div className="flex items-center justify-center p-1 w-36 bg-bgButton rounded-lg">
                        <button onClick={onConfirm}>
                            <div className="flex flex-row justify-center items-center text-white">
                                <span className="mr-2">Ver Carrinho</span>
                                <IoArrowRedoCircleOutline className="w-7 h-7" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
            {downloading && <Spinner/>}    
        </div>
    );
};


export default Menu;

