
import { Html5Qrcode } from "html5-qrcode";
import React, { useState, useRef, useEffect } from 'react';
import { splitQRCodeInfo } from '../services/data';
import Modal from 'react-modal';

const QRScan = ({ visible, setVisible, setQrCodeInfo }) => {
  // const [isScanning, setIsScanning] = useState(false);
  const [qrCodeScanner, setQrCodeScanner] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [isScanned, setIsScanned] = useState(false);

  useEffect(() => {

    if (visible) {
      try {
        const interval = setInterval(() => {
          clearInterval(interval);
          startCamera();
        }, 200);

      }
      catch {

      }
    }


  }, [visible])

  useEffect(() => {
    if (isScanned && qrCode !== null) {

      //prevent entering in this use effect again
      setIsScanned(false);

      setQrCodeInfo(splitQRCodeInfo(qrCode));

      stopCamera()

      //onQrCodeScanned();
    }
  }, [qrCode])

  const startCamera = () => {
    const html5QrCode = new Html5Qrcode("reader");

    // Configure the scanner to start scanning
    html5QrCode.start(
      { facingMode: "environment" }, // Start with rear camera
      { fps: 10 },
      onScanSuccess,
      onScanError
    ).then(() => {
      setQrCodeScanner(html5QrCode);
      // setIsScanning(true);
    }).catch(err => {
      console.error("Error starting the camera: ", err);
    });
  };

  const stopCamera = () => {

    if (qrCodeScanner) {
      qrCodeScanner.stop().then(() => {

        // Clear the scanner after stopping it
        qrCodeScanner.clear();
        setQrCode(null);

        setVisible(false);

      }).catch(err => {
        console.error("Error stopping the camera: ", err);
      });
    }
  };


  // Called when QR code is successfully scanned
  const onScanSuccess = (decodedText, decodedResult) => {

    setIsScanned(true);

    setQrCode(decodedText);


  };


  // Called when there is an error during scan
  const onScanError = (error) => {
    //console.error(`Scan error: ${error}`);
  };

  const onCloseCamera = () => {
    stopCamera()
  }

  return (
    <div>
      <Modal
        isOpen={visible}
        onRequestClose={() => onCloseCamera()}
        className="flex justify-center items-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center"
        ariaHideApp={false} // Necessary for accessibility; you might want to enable this based on your app setup
      >
        <div className="flex flex-col items-center">
          <div className="text-lg text-white font-arial">
            Para iniciar um novo pedido,
          </div>
          <div className="text-lg text-white font-arial">
            leia o QRCode do AiGarçom mais próximo.
          </div>
          <div className="mt-2 w-[80vw] max-w-[300px] md:w-[400px] mx-auto">
            <div id="reader"></div>
          </div>
          <div>
            <button onClick={onCloseCamera} className="mt-3 bg-transparent border border-blue-500 p-2 rounded-lg w-24 text-center">
              <p className="text-base font-normal text-blue-500">Fechar</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QRScan;
