import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';

import { environment } from '../services/environment';
import ErrorPage from './errorPage';
import Spinner from './spinner';

import { IoEye } from 'react-icons/io5';
import { IoEyeOff } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [userEmail, setUserEmail] = useState('');
    const [userPwd, setUserPwd] = useState('');
    const [errModalVisible, setErrModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [securePwd, setsecurePwd] = useState(true);

    const [downloading, setDownloading] = useState(false);

    const navigate = useNavigate();

    const tooglePwd = () => {
        setsecurePwd(!securePwd);
    }


    const forgotPwd = () => {
        console.log("Forgot Password")
        //RootNavigation.navigate('ForgotPwd', { "userEmail": userEmail });
    }


    function onEmailPwdLogin() {
        setErrorMessage("");

        if (userEmail == "") {
            setErrorMessage("Digite o email do usuário!");
            return;
        }
        else if (userPwd == "") {
            setErrorMessage("Digite a senha do usuário!");
            return;
        }

        userLogin();
    }


    const userLogin = () => {
        setDownloading(true);

        const userData = {
            "UserEmail": userEmail,
            "UserPwd": userPwd
        };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, environment.timeoutValue);

        //console.log("timeoutValue", environment.timeoutValue);

        const response = fetch(environment.centralUrl + 'VendorUserLogin',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${environment.preUser}`
                },
                body: JSON.stringify(
                    userData
                ),
                signal
            })
            .then((response) => {
                // console.log("response", response);
                if (!response.ok) {
                    // console.log("Error response", response);
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }
                return response.json()
            })
            .then((json) => {
                if (json.validated == "OK") {

                    // userInfo = {
                    //     "Validated": "OK",
                    //     "UserID": userID,
                    //     "UserName": userName,
                    //     "UserEmail": userEmail,
                    //     "UserRole": userRole,
                    //     "RoleDescription": roleDescription,
                    //     "IsEmailVerified": isEmailVerified,
                    //     "UserToken": token,
                    //     "VerificationCode" : verificationCode,
                    //     "VendorID" : vendorID,
                    //     "VendorName" : vendorName,
                    // }

                    localStorage.setItem('loggedUser', JSON.stringify(json));

                    navigate("/dashboard");

                }
                else {
                    setErrorMessage("Usuário ou senha invalidos!");
                }

            })
            .catch((error) => {
                setErrorMessage(error.message);
                setErrModalVisible(true);

            })
            .finally(() => {
                setDownloading(false);
                //console.log('End AdsSearchResult')
            })
    }

    const closeErrModal = () => {
        setErrModalVisible(false);
    }

    const retry = () => {
        setErrModalVisible(false);
        userLogin()
    }

    return (
        <div className="flex flex-col min-h-screen">
            <nav className="fixed w-full bg-bgTitle">
                <div className="mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex flex-shrink-0 items-center">
                            <img
                                src="https://smartapplications.com.br/images/logoaigarcom2.png"
                                alt="Logo"
                                className="h-6"
                            />
                        </div>

                    </div>
                </div>
            </nav>

            <div className="flex flex-col h-screen items-center justify-center py-8 px-6 bg-white">
                <div className="w-1/3" style={{ height: '78vh' }}>

                    <p className="mt-8">Email do usuário</p>
                    <input
                        className="w-full p-2 border rounded"
                        value={userEmail}
                        placeholder="Digite seu email"
                        onChange={(e) => setUserEmail(e.target.value)}
                    />

                    <p className="mt-4">Senha</p>
                    <div className="flex items-center border p-2 rounded">
                        <input
                            className="w-full p-2 border rounded"
                            type={securePwd ? "password" : "text"}
                            value={userPwd}
                            placeholder="Digite sua senha"
                            onChange={(e) => setUserPwd(e.target.value)}
                        />
                        <button onClick={tooglePwd} className="ml-4">
                            {securePwd ? <IoEye className="h-6 w-6" /> : <IoEyeOff className="h-6 w-6" />}
                        </button>
                    </div>

                    <p className="text-center text-red-500 h-5">{errorMessage}</p>

                    <div className='flex justify-center'>
                        <button
                            className="w-1/2 mt-4 py-2 bg-blue-500 text-white font-bold rounded"
                            onClick={onEmailPwdLogin}
                        >
                            OK
                        </button>
                    </div>
                    <div className="mt-20 text-center">
                        <button onClick={forgotPwd} className="text-blue-500 underline">
                            Esqueci a senha
                        </button>
                    </div>

                    {/* <div className="flex justify-center items-center mt-8 h-10">
                        <button onClick={onOffline} className="flex items-center">
                            <Ionicons name="cloud-offline-outline" size={22} className="mr-2" />
                            <span className="text-blue-500 font-bold">Modo Emergência</span>
                        </button>
                    </div> */}
                </div>
            </div>
            {downloading && <Spinner />}
            <ErrorPage errorMessage={errorMessage} visible={errModalVisible} closeModal={closeErrModal} retry={retry} />
        </div>
    );
};



export default Login;
