import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

const ErrorPage = ({ errorMessage, visible, closeModal, 
    retry, retryBtn = true, closeBtn = false }) => {
    
    const navigate = useNavigate();

    //console.log("Error page => close btn", closeBtn );

    const back = () => {
        navigate("/home");
    }

    return (
        <div className="flex flex-1 justify-center items-center">
          <Modal
            isOpen={visible}
            onRequestClose={closeModal}
            className="flex justify-center items-center"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            ariaHideApp={false} // Necessary for accessibility; you might want to enable this based on your app setup
          >
            <div className="bg-white rounded-lg p-6 w-[300px] h-[200px] shadow-lg">
              <div className="flex flex-col items-center mt-5">
                <span className="text-black text-lg">Ops! Algo deu errado.</span>
                <span className="text-black text-base mt-5">{errorMessage}</span>
              </div>
              <div className="flex flex-row justify-center mt-5">
                {retryBtn && (
                  <button
                    onClick={retry}
                    className="mx-1 px-4 py-2 bg-blue-500 text-white rounded-md"
                  >
                    Tentar novamente
                  </button>
                )}
                {closeBtn && (
                  <button
                    onClick={closeModal}
                    className="mx-1 px-4 py-2 bg-gray-500 text-white rounded-md"
                  >
                    Fechar
                  </button>
                )}
              </div>
            </div>
          </Modal>
        </div>
      );
};

export default ErrorPage;


